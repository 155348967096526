<template>
  <div>
    <br />

    <el-row :gutter="80">
      <el-col :span="12">
        <div class="databox-g">
          <h4 class="center-g">{{ message.title }}</h4>
          <pre>{{ message.content }}</pre>
          <img
            v-if="message.img"
            :src="'https://server.solar960.com' + message.img"
            width="100%"
            alt
            style="padding: 10px 0"
          />
        </div>
      </el-col>
      <el-col :span="12">
        <el-form :model="message" :rules="rules" ref="message" size="mini">
          <el-form-item label="标题" prop="title">
            <el-input v-model="message.title" placeholder="标题" maxlength="15"></el-input>
          </el-form-item>
          <el-form-item label="图片" prop="img">
            <el-upload
              v-show="!message.img"
              class="avatar-uploader"
              action="https://server.solar960.com/api/fileUpload"
              :headers="headerObj"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              :on-success="handleAvatarSuccess"
              :on-progress="uploadProcess"
              :on-remove="handleRemove"
            >
              <i
                v-show="message.img == '' && imgFlag == false"
                class="el-icon-plus avatar-uploader-icon"
              ></i>
              <el-progress
                v-show="imgFlag == true"
                type="circle"
                :percentage="percent"
                style="margin-top: 20px"
              ></el-progress>
            </el-upload>

            <div class="image-preview" v-show="message.img">
              <div class="image-preview-wrapper">
                <img :src="'https://server.solar960.com' + message.img" />
                <div class="image-preview-action">
                  <i @click="handleRemove" class="el-icon-delete"></i>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="内容" prop="content">
            <el-input type="textarea" v-model="message.content" :autosize="{ minRows: 5 }"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('message')">确定发送</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { createMessage, deleteFile } from "@/api/api";
export default {
  //message,bizdetail,storedetail,userdetail
  props: ["custId"], //custId,messageLog的添加对象;isShow，是否添加的控制;
  data() {
    return {
      headerObj: {
        Accept: "application/x.server.v1+json",
        Authorization: sessionStorage.getItem("Authorization")
      },
      rules: {
        title: [{ required: true, message: "必填", trigger: "blur" }]
      },
      message: {
        type: "private",
        title: "",
        img: "",
        content: ""
      },
      imgFlag: false,
      percent: 0
    };
  },
  methods: {
    uploadProcess(event) {
      this.imgFlag = true;
      this.percent = Math.floor(event.percent);
    },
    //msgSn: 0, user新人消息； 1，store新人消息； 2，biz新人消息
    //新人消息可编辑，不能增加和删除。在添加User时，审核通过biz和store，写入messageLog。
    //其他所有消息，在创建消息或者用户登陆时，写入messageLog。
    submitForm(formName) {
      const that = this;
      if (this.message.img == "" && this.message.content == "") {
        alert("图片和文字不能同时为空！");
        return;
      }
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$confirm("确定发送消息?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          })
            .then(() => {
              that.message.type = that.custId;
              createMessage(that.message).then(res => {
                if (res.result) {
                  this.message = {
                    type: "private",
                    title: "",
                    img: "",
                    content: ""
                  };
                  that.$message({
                    type: "success",
                    message: "发送成功"
                  });
                  that.$emit("close");
                }
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "取消"
              });
            });
        } else {
          return false;
        }
      });
    },
    handleRemove() {
      deleteFile({
        file: this.message.img
      }).then(res => {
        console.log(res);
      });
      this.message.img = "";
    },
    handleAvatarSuccess(res) {
      this.imgFlag = false;
      this.percent = 0;
      if (res) {
        let imgUrl = res.data.files;
        this.message.img = imgUrl;
        this.$message.success("图片上传成功！");
      } else {
        this.$message.error("图片上传失败，请重新上传！");
      }
    },
    beforeAvatarUpload(file) {
      //限制格式
      const isJPG =
        (file.type === "image/jpg") |
        (file.type === "image/png") |
        (file.type === "image/jpeg");
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG、JPEG 或 PNG 格式!");
      }
      return isJPG;
    }
  }
};
</script>

<style scoped>
h4 {
  border-bottom: 1px solid #aaa;
  padding-bottom: 10px;
}

h4 span {
  color: darkorange;
  margin-left: 30px;
  font-size: 16px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 178px;
  height: 178px;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.image-preview {
  width: 178px;
  height: 178px;
  position: relative;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  float: left;
}

.image-preview .image-preview-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.image-preview .image-preview-wrapper img {
  width: 100%;
  height: 100%;
}

.image-preview .image-preview-action {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: default;
  text-align: center;
  color: #fff;
  opacity: 0;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s;
  cursor: pointer;
  text-align: center;
  line-height: 200px;
}

.image-preview .image-preview-action .el-icon-delete {
  font-size: 32px;
}

.image-preview:hover .image-preview-action {
  opacity: 1;
}
.avatar-uploader-icon {
  border: 1px dashed #cecece;
}
</style>