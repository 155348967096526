<template>
  <div>
    <p>客户名称：{{ companyName }}</p>
    <p>原手机号： {{ phoneBefore }}</p>
    <br />
    <label for>输入新手机号</label>
    <el-input type="text" v-model="regphone"></el-input>
    <br />
    <br />
    <label for>上传证明材料</label>
    <el-upload
      action="https://server.solar960.com/api/fileUploadWithThumb"
      :headers="headerObj"
      :multiple="false"
      :before-upload="beforeAvatarUpload"
      :on-success="handleAvatarSuccess"
      class="center-g btnwrap"
      :limit="1"
      :on-remove="handleRemove"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <br />
    <br />
    <el-button type="primary" @click="submit">确定更改</el-button>
    <!-- 照片预览弹出框 -->
    <el-dialog :visible.sync="dialogVisible" append-to-body destroy-on-close>
      <img width="100%" :src="dialogImageUrl" alt />
    </el-dialog>
  </div>
</template>

<script>
// 父组件views/bizdetail，storedetail，
import { adminUpdatePhone, deleteFile } from "@/api/api";
export default {
  props: ["custId", "phoneBefore", "companyName"],
  data() {
    return {
      regphone: "",
      headerObj: {
        Accept: "application/x.server.v1+json",
        Authorization: sessionStorage.getItem("Authorization")
      },
      dialogImageUrl: "",
      dialogVisible: false
    };
  },
  methods: {
    handleRemove() {
      deleteFile({
        file: this.dialogImageUrl
      }).then(res => {
        console.log(res);
      });
      this.dialogImageUrl = "";
    },
    handleAvatarSuccess(res) {
      this.dialogImageUrl = res.data.files;
    },
    beforeAvatarUpload(file) {
      //限制格式
      const isJPG =
        (file.type === "image/jpg") |
        (file.type === "image/png") |
        (file.type === "image/jpeg");
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG、JPEG 或 PNG 格式!");
      }
      return isJPG;
    },

    ValidationPhoneNumber() {
      let pattern = /^1[3456789]\d{9}$/;
      let test = pattern.test(this.regphone);
      if (test) {
        return true;
      } else {
        this.$alert("请输入正确的手机号码", {
          confirmButtonText: "确定",
          type: "error",
          callback: () => {}
        });
      }
    },
    submit() {
      const that = this;
      if (!this.ValidationPhoneNumber()) {
        return false;
      }
      if ((this.regphone == "") | (this.dialogImageUrl == "")) {
        alert("填写手机号及上传证明材料");
      } else {
        adminUpdatePhone({
          phone: that.regphone,
          value: that.dialogImageUrl,
          custId: that.custId,
        }).then(res => {
          if (res.result) {
            that.$message.success("操作成功");
          }
        });
        this.$emit("close");
      }
    }
  }
};
</script>

<style>
</style>