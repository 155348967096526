<template>
  <div>
    <el-table :data="orders" style="width: 100%" size="small">
      <el-table-column
        :prop="key"
        :label="value"
        v-for="(value, key) in tableList.orderShortHead"
        :key="key"
      ></el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="getDetail(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="dialogVisible" width="90%" top="8vh">
      <div style="margin: 30px">
        <el-descriptions title="订单详情" :column="3" border style="margin:20px 0">
          <el-descriptions-item
            v-for="(value, key) in tableList.orderHead"
            :key="key"
            :label="value"
          >{{ orderShow[key] }}</el-descriptions-item>
        </el-descriptions>

        <el-table :data="prodShow" style="width: 100%">
          <el-table-column
            :prop="key"
            :label="value"
            v-for="(value, key) in tableList.prodHead"
            :key="key"
          ></el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
//父组件 storeDetail, userdetail, orders
export default {
  props: ["orders"],
  // custId=admin, 取3日所有数据， custId=bizid|idUser，取所有该store或者user的数据
  data() {
    return {
      dialogVisible: false,
      orderShow: {}, //弹出框的订单信息字段
      prodShow: [],
      tableList: {
        orderShortHead: {
          orderSn: "订单号",
          amount: "金额",
          nameStore: "商家名称",
          storeTypeName: "商家类别",
          nickName: "用户昵称 ",
          orderFromName: "APP",
          genTime: "时间"
        },
        orderHead: {
          orderSn: "订单号",
          state: "状态",
          settleDate: "消费/退款时间",
          storeTypeName: "商家类别",
          idStore: "商家编号",
          nameStore: "商家名称",
          idUser: "用户编号",
          nickName: "用户昵称 ",
          orderFromName: "APP",
          amount: "金额",
          cashAmount: "现金",
          par: "优惠券金额",
          incomeStore: "商家收入",
          incomeAxiche: "平台佣金",
          cashChannelName: "支付渠道",
          balanceDate: "渠道结算时间",
          couponCode: "券码",
          genTime: "下单时间"
        },
        prodHead: {
          orderSn: "订单编号",
          id: "商品编号",
          prodName: "商品名称",
          prodPrice: "商品价格",
          quantity: "数量"
        }
      }
    };
  },
  methods: {
    getDetail(item) {
      this.orderShow = item;
      this.prodShow = item.products;
      this.dialogVisible = true;
    }
  }
};
</script>

<style scoped>
</style>