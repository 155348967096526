<template>
  <div>
    <el-tabs v-model="activeName">
      <el-button type="warning" size="mini" @click="back">《 返回</el-button>

      <el-tab-pane label="基本信息" name="1">
        <el-descriptions title="基本信息" :column="3" border style="margin: 40px 20px;">
          <template slot="extra" style="display:flex">
            <el-button type="primary" @click="toSight" size="mini">景点详情</el-button>
            <el-button type="primary" @click="messageCreateShow = true" size="mini">发送消息</el-button>
            <el-button type="primary" @click="changePhoneShow = true" size="mini">更改注册手机</el-button>
            <el-button
              v-if="sightCompany.state == 1"
              type="primary"
              @click="freeAccount()"
              size="mini"
            >解禁</el-button>
            <el-button
              v-if="sightCompany.state == 0"
              type="danger"
              @click="balckAccount()"
              size="mini"
            >禁用</el-button>
          </template>
          <el-descriptions-item v-for="(value, key) in listLabel" :key="key" :label="value">
            <div v-if="key=='license'" @click="previewImg(sightCompany.license)">
              <img :src="sightCompany[key]" width="40px" height="40px" alt />
            </div>
            <div v-else>{{sightCompany[key]}}</div>
          </el-descriptions-item>
        </el-descriptions>
      </el-tab-pane>
      <el-tab-pane label="账户" name="2">
        <accountinfo :custId="sightCompany.id" :custName="sightCompany.name" />
      </el-tab-pane>

      <el-tab-pane label="订单" name="3">
        <el-tabs v-model="activeNameOrder" @tab-click="handleOrderTabChange">
          <el-tab-pane label="景区订单" name="1"></el-tab-pane>
          <el-tab-pane label="预定订单" name="2"></el-tab-pane>
          <el-tab-pane label="退款订单" name="3"></el-tab-pane>
        </el-tabs>
        <orderlisttable :orders="orders" />
        <el-pagination
          :page-size="30"
          :pager-count="9"
          :current-page.sync="page"
          layout="total, prev, pager, next"
          :total="total"
          hide-on-single-page
          @current-change="handleCurrentChange"
        ></el-pagination>
      </el-tab-pane>
      <el-tab-pane label="成员" name="5">
        <table>
          <thead>
            <td v-for="(item, key) in casher" :key="key">{{ item }}</td>
          </thead>
          <tr v-for="(item, index) in membBiz" :key="index">
            <td v-for="(value, key) in casher" :key="key">
              <span v-if="key == 'avatarUrl'">
                <img :src="item[key]" width="30px" alt />
              </span>
              <span v-else>{{ item[key] }}</span>
            </td>
          </tr>
        </table>
      </el-tab-pane>
      <el-tab-pane label="消息" name="6">
        <messagelist :custId="idSightCompany" :custType="'sight'" />
      </el-tab-pane>
    </el-tabs>
    <!-- 更改注册手机弹出框 -->
    <el-dialog :visible.sync="changePhoneShow" title="更改注册手机" destroy-on-close>
      <changephone
        :custId="idSightCompany"
        :phoneBefore="sightCompany.regphoneUser"
        :companyName="sightCompany.name"
        :custType="'sight'"
        @close="changePhoneShow = false"
      />
    </el-dialog>
    <!-- 发送消息弹出框 -->
    <el-dialog :visible.sync="messageCreateShow" title="发送消息" destroy-on-close width="80%">
      <messagecreate :custId="idSightCompany" @close="messageCreateShow = false" />
    </el-dialog>
    <!-- 照片放大弹出框 -->
    <el-dialog :visible.sync="picShow" width="900px">
      <img :src="imgForPreviw" width="800px" alt />
    </el-dialog>
  </div>
</template>

<script>
import {
  AdminGetSightCompanyDetail,
  adminUpdateSightCompanyState,
  adminGetOrderList,
  getMemberStores
} from "@/api/api";
import accountinfo from "../components/AccountInfo";
import messagecreate from "../components/MessageCreat";
import messagelist from "../components/MessageList";
import changephone from "../components/ChangePhone";
import orderlisttable from "../components/OrderListTable";
export default {
  components: {
    accountinfo,
    changephone,
    messagecreate,
    messagelist,
    orderlisttable,
  },
  data() {
    return {
      idSightCompany: JSON.parse(this.$route.query.id),
      changePhoneShow: false,
      messageCreateShow: false,
      picShow: false,
      activeName: "1",
      imgForPreviw: "",
      sightCompany: {},
      membBiz: [],
      activeNameOrder: "1",
      page: 1,
      state: 1,
      orders: [],
      total: 0,

      listLabel: {
        id: "编号",
        name: "名称",
        noteNameBiz: "简称",
        companyCode: "统一代码",
        license: "执照",
        stateName: "状态",
        province: "省",
        city: "城市",
        district: "区县",
        address: "地址",
        adcode: "区码",
        contactPerson: "联系人",
        regphoneUser: "电话",
        genTime: "注册时间",
        ticketSellName: "网络售票",
        cashIdUser: "收款人ID",
        rate: "费率",
        idSight: "景区ID",
        sightName: "景区名称",
        typeSight: "景区类别"
      },
      casher: {
        id: "编号",
        membTypeName: "权限",
        idUser: "成员IDUSER",
        regphoneUser: "手机号",
        nickName: "昵称",
        avatarUrl: "头像",
        noteName: "备注名",
        genTime: "添加时间"
      }
    };
  },
  mounted() {
    this.getSightCompanyDetail();
    this.getOrderListFn();
    this.getBizMemberFn();
  },
  methods: {
    getSightCompanyDetail() {
      const that = this;
      AdminGetSightCompanyDetail({
        id: that.idSightCompany
      }).then(res => {
        if (res.result) {
          that.sightCompany = res.data;
        }
      });
    },
    getBizMemberFn() {
      const that = this;
      getMemberStores({
        idStore: that.idSightCompany
      }).then(res => {
        if (res.result) {
          that.membBiz = res.data;
        }
      });
    },
    toSight() {
      this.$router.push({
        path: "/sights/sightdetail",
        query: { id: this.sightCompany.idSight }
      });
    },
    freeAccount() {
      const that = this;
      this.$confirm("确认解禁该账户？", "解禁", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          adminUpdateSightCompanyState({
            id: that.sightCompany.id,
            state: 0,
            value: ""
          }).then(res => {
            if (res.result) {
              that.$message({
                type: "success",
                message: "更改成功!"
              });
              that.sightCompany.state = "0";
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消更改"
          });
        });
    },
    balckAccount() {
      const that = this;
      this.$prompt("请输入禁用的原因", "禁用商家账户", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^\S{1,}$/,
        inputErrorMessage: "请输入原因",
        closeOnClickModal: false
      })
        .then(({ value }) => {
          adminUpdateSightCompanyState({
            id: that.sightCompany.id,
            state: 1,
            value: value
          }).then(res => {
            if (res.result) {
              that.$message({
                type: "success",
                message: "已禁用该账户"
              });
              that.sightCompany.state = "1";
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消"
          });
        });
    },
    previewImg(item) {
      this.imgForPreviw = item.replace(".thumb", "");
      this.picShow = true;
    },
    getOrderListFn() {
      const that = this;
      adminGetOrderList({
        page: that.page,
        storeType: 1,
        state: that.state,
        idUser: "",
        idStore: that.idSightCompany
      }).then(res => {
        if (res.result) {
          let oldOrders = that.orders;
          that.orders = oldOrders.concat(res.data.orders);
          that.total = res.data.total;
        }
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getOrderListFn();
    },
    handleOrderTabChange() {
      switch (this.activeNameOrder) {
        case "1":
          this.page = 1;
          this.orders = [];
          this.state = 1;
          break;
        case "2":
          this.page = 1;
          this.orders = [];
          this.state = 0;
          break;
        case "3":
          this.page = 1;
          this.orders = [];
          this.state = -1;
          break;
      }
      this.getOrderListFn();
    },
    back() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
.contentbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 40px;
  flex-wrap: wrap;
  padding-top: 20px;
  border-top: 1px dashed #bbb;
}
.itembox {
  margin: 10px;
  text-align: center;
  line-height: 30px;
  /* border: 1px solid #ccc;
  border-radius: 5px; */
  padding: 5px 10px;
}
</style>