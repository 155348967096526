<template>
  <div>
    <div>
      <!-- 账户操作，内部账户不能调账和禁止 -->
      <div v-show="subaccount.custType != 10" class="btnwrap-g">
        <el-button type="primary" @click="show = true" size="mini">调账</el-button>
        <!-- user不能禁止提现 -->
        <span v-if="subaccount.custType != 'user'">
          <el-button
            type="danger"
            @click="freezeAccount(1)"
            v-if="subaccount.state == 0"
            size="mini"
          >禁止提现</el-button>
          <el-button
            type="success"
            @click="freezeAccount(0)"
            v-if="subaccount.state == 1"
            size="mini"
          >允许提现</el-button>
        </span>
      </div>
    </div>
    <h4>账户信息: {{ custName }}</h4>
    <table>
      <thead>
        <td v-for="(item, index) in accountHead" :key="index">{{ item }}</td>
      </thead>
      <tr>
        <td v-for="(value, key) in accountHead" :key="key">{{ subaccount[key] }}</td>
      </tr>
    </table>
    <!-- 调账弹出框 -->
    <el-dialog :visible.sync="show" top="2vh">
      <div style="padding: 30px">
        <h2>调账</h2>
        <h3 style="border-bottom: 1px dashed #aaa">
          <span>客户名称： {{ custName }}</span>
          <span style="margin-left: 60px">客户ID: {{ subaccount.custId }}</span>
        </h3>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="转入" name="转入"></el-tab-pane>
          <el-tab-pane label="转出" name="转出"></el-tab-pane>
          <el-tab-pane label="商家后台充值" name="后台充值" v-if="subaccount.custType == 3">
            <p style="color: red">
              注：
              如果商家采用预充值后结款方式，后台充值后应设置该商家账户为禁止提现。
            </p>
          </el-tab-pane>
          <div>
            <p>转账金额</p>
            <el-input v-model="dataInput.amount" oninput="value=value.replace(/^\.+|[^\d.]/g,'')"></el-input>
            <p>备注</p>
            <el-input type="textarea" v-model="dataInput.remark" :autosize="{ minRows: 5 }"></el-input>
            <div class="btnwrap-g">
              <el-button type="primary" @click="adjustAccount">确定</el-button>
            </div>
          </div>
        </el-tabs>
      </div>
    </el-dialog>
    <div>
      <h4>账户明细</h4>
      <table>
        <thead>
          <td v-for="(item, index) in accountHistoryHead" :key="index">{{ item }}</td>
        </thead>
        <tr v-for="(item, index) in accountHistory" :key="index">
          <td
            v-for="(value, key) in accountHistoryHead"
            :key="key"
            class="accountdetail"
          >{{ item[key] }}</td>
        </tr>
      </table>
      <br />
      <el-pagination
        :page-size="20"
        :current-page.sync="pageNumber"
        layout="total, prev, pager, next"
        :total="total"
        hide-on-single-page
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {
  updateAccountState,
  adjustAccountApi,
  adminGetSubaccount
} from "@/api/api";
//父组件： views/bizdetail,userdetail, storedetail, accounts
export default {
  props: ["custId", "custName"], //根据custId，取数据，custName,前端显示用
  data() {
    return {
      show: false,
      pageNumber: 1,
      activeName: "转入",
      dataInput: {
        //调账输入
        remark: "",
        amount: ""
      },
      accountHead: {
        subaccountId: "子账户编号",
        custId: "用户编号",
        custType: "用户类别",
        state: "状态",
        balance: "余额",
        freezeAmount: "业务冻结",
        freezeCashAmount: "提现冻结",
        genTime: "生成时间"
      },
      accountHistoryHead: {
        accountSn: "流水号",
        amount: "发生额",
        seqFlag: "变动类别",
        preAmount: "变动前",
        afterAmount: "变动后",
        freezeAmount: "冻结额",
        type: "交易类别",
        peer: "对手ID",
        peerName: "对手名称",
        refSn: "凭证",
        refType: "凭证类别",
        genTime: "发生时间"
      },
      transaction: [], //调账和商家后台充值时
      accountHistoryAdd: [], //调账和商家后台充值时
      settingHistory: {
        settingSn: "",
        objId: "",
        objName: "",
        setting: "",
        remark: "",
        genTime: "",
        operator: ""
      },
      //requested data
      subaccount: {},
      total: 0, //子账户流水的计数
      accountHistory: []
    };
  },

  mounted() {
    this.adminGetSubaccountFn();
  },

  watch: {
    // 监听的对象
    custId: {
      // 数据变化回调方法
      handler() {
        this.adminGetSubaccountFn();
      },
      // 发生变化了，立即调用回调方法
      immediate: true,
      // 监听对象里的字段变化？
      deep: true
    }
  },

  methods: {
    adminGetSubaccountFn() {
      const that = this;
      if (!that.custId) {
        return;
      }
      adminGetSubaccount({
        custId: that.custId,
        page: that.pageNumber,
        count: 20
      }).then(res => {
        if (res.result) {
          that.accountHistory = res.data.accountHistory;
          that.total = res.data.count;
          that.subaccount = res.data.subaccount;
        }
      });
    },

    freezeAccount(state) {
      const that = this;
      var action = "";
      if (state == 1) {
        action = "禁止账户提现";
      } else {
        action = "允许账户提现";
      }
      this.$prompt("请输入" + action + "的原因", action, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^\S{1,}$/,
        inputErrorMessage: "填写原因",
        closeOnClickModal: false
      })
        .then(({ value }) => {
          updateAccountState({
            value: value,
            custId: that.subaccount.custId,
            state: state
          }).then(res => {
            if (res.result) {
              that.$message({
                type: "success",
                message: "已" + action
              });
              that.adminGetSubaccountFn();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消"
          });
        });
    },
    handleCurrentChange(val) {
      //分页的点击事件
      this.pageNumber = val;
      this.adminGetSubaccountFn();
    },
    handleClick() {
      //调账弹出框的tab的点击事件
      this.dataInput.amount = "";
      this.dataInput.remark = "";
      this.transaction = [];
      this.accountHistoryAdd = [];
      this.settingHistory = {
        settingSn: "",
        objId: "",
        objName: "",
        setting: "",
        remark: "",
        genTime: "",
        operator: ""
      };
    },
    checkInput() {
      //调账的输入检查
      if ((this.dataInput.amount <= 0) | (this.dataInput.remark == "")) {
        alert("所有项必填");
        return false;
      } else {
        if (this.activeName == "转出") {
          var result =
            (parseFloat(this.subaccount.balance) * 100 +
              parseFloat(this.dataInput.amount) * 100) /
            100;
          if (result < 0) {
            alert("余额不足");
            return false;
          }
        }
        return true;
      }
    },
    adjustAccount() {
      const that = this;
      if (!this.checkInput()) {
        return;
      } else {
        if (this.activeName == "后台充值") {
          that.dataInput.remark =
            that.dataInput.remark + "。账号已设置为禁止提现。";
        }
        adjustAccountApi({
          activeName: that.activeName,
          amount: that.dataInput.amount,
          remark: that.dataInput.remark,
          custId: that.subaccount.custId
        }).then(res => {
          if (res.result) {
            that.$alert("平台调账成功", {
              confirmButtonText: "确定",
              callback: () => {
                this.show = false;
                if (
                  (this.activeName === "后台充值") &
                  (this.subaccount.state === "0")
                ) {
                  //后台充值时，如果商家账户处于可提现状态，禁止商家提现
                  this.freezeAccount(1);
                }
              }
            });
          }
        });
      }
    }
  }
};
</script>

<style scoped>
.btnwrap-g span {
  margin-left: 30px;
}
.accountdetail {
  max-width: 90px;
  word-wrap: break-word;
  word-break: break-all;
  padding: 0 5px;
}
</style>